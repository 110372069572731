import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../componentsNonAdmin/Banner";
import CallToAction from "../componentsNonAdmin/CallToAction";
import Separation from "../componentsNonAdmin/Separation";
import Connections from "../componentsNonAdmin/Connections";
import Services from "../componentsNonAdmin/Services";
import Expertise from "../componentsNonAdmin/Expertise";
import NewsBlock from "../components/NewsBlock";
import News from "../componentsNonAdmin/News";
import ContactWithUs from "../componentsNonAdmin/ContactWithUs";

const IndexPage = ({ data, location, pageContext }) => {
  const { t } = useTranslation()
  const siteTitle = data.site.siteMetadata.title
  const content = data?.wpPage?.content
  const seo = data?.wpPage?.seo
  const settings = data?.wpPage?.settings

  return (
    <Layout location={location} title={siteTitle} isHomePage={true}>
      <Seo
        title={seo?.title || t("eRetail платформа DV Group")}
        description={seo?.metaDescription || ""}
        keywords={seo?.metaKeywords || ""}
      />
      <Banner />
      <CallToAction />
      <Separation disableMarginTop />
      <Connections />
      <Separation disableMarginTop />
      <Services />
      <Separation />
      <Expertise />
      <Separation />
      <News pageContext={pageContext} />
      <Separation />
      <ContactWithUs />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(isFrontPage: { eq: true }, status: { eq: "publish" }) {
      content
      seo {
        metaDescription
        metaKeywords
        title
      }
      settings {
        customStyles
        rbeaStyles
        language
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
