import React from 'react'
import * as styles from "./Connections.module.css";
import cx from "classnames";
import IconBasket from "../../assets/images/Icon.Basket.svg";
import IconUserId from "../../assets/images/Icon.UserId.svg";

const HowUseOnlineBills = () => {
  return (
    <div className={styles.contentContainer}>
      <h3 className={styles.contentTitle}>Как мы используем online-чеки</h3>
      <div className={styles.contentInfo}>
        <div className={styles.howUsingFeaturesList}>
          <h3 className={styles.howUsingTitleTablet}>Как мы используем<br/> online-чеки</h3>
          <div className={styles.howUsingFeature}>
            <div className={styles.howUsingIcon}>
              <img src={IconBasket} alt={""}/>
            </div>
            <div className={styles.howUsingFeatureContent}>
              <p className="text-subheader">Online транзакции</p>
              <p className="text-standart">полная детализация по SKU и месту покупки</p>
            </div>
          </div>

          <div className={styles.howUsingFeature}>
            <div className={styles.howUsingIcon}>
              <img src={IconUserId} alt={""}/>
            </div>
            <div className={styles.howUsingFeatureContent}>
              <p className="text-subheader">Единый ID покупателя</p>
              <p className="text-standart">по всем интернет-магазинам и маркетплейсам</p>
            </div>
          </div>
        </div>
        <div className={styles.howUsingFlow}>
          <div className={styles.howUsingFlowItem}>
            <div className={styles.flowItemContainer}>
              <div className={styles.flowItemTitle}>
                <span className={cx("text-h1", styles.flowItemTitleIcon)}>&gt;</span>
                <span className="text-subheader">Как получаем</span>
              </div>
              <p className={cx("text-standart", styles.flowItemDescription)}>Через подключение к данным ККТ (контрольно-кассовые терминалы обслуживающие маркетплейсы)</p>
            </div>
          </div>
          <div className={styles.howUsingFlowItem}>
            <div className={styles.flowItemContainer}>
              <div className={styles.flowItemTitle}>
                <span className={cx("text-h1", styles.flowItemTitleIcon)}>&gt;</span>
                <span className="text-subheader">Что видим</span>
              </div>
              <p className={cx("text-standart", styles.flowItemDescription)}>Состав корзины покупателя (SKU, сумма и время покупки)</p>
            </div>
          </div>
          <div className={styles.howUsingFlowItem}>
            <div className={styles.flowItemContainer}>
              <div className={styles.flowItemTitle}>
                <span className={cx("text-h1", styles.flowItemTitleIcon)}>&gt;</span>
                <span className="text-subheader">Применение</span>
              </div>
              <p className={cx("text-standart", styles.flowItemDescription)}>Анализ потребительских привычек пользователя и вычисление вероятности следующей покупки</p>
            </div>
          </div>
          <div className={styles.howUsingFlowItem}>
            <div className={styles.flowItemContainer}>
              <div className={styles.flowItemTitle}>
                <span className={cx("text-h1", styles.flowItemTitleIcon)}>&gt;</span>
                <span className="text-subheader">Как распознаём</span>
              </div>
              <p className={cx("text-standart", styles.flowItemDescription)}>Определяем магазин и категорию на основе предиктивной модели</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HowUseOnlineBills
