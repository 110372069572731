// extracted by mini-css-extract-plugin
export var catalog = "newsBlock-new-module--catalog--095f5";
export var container = "newsBlock-new-module--container--66e87";
export var date = "newsBlock-new-module--date--9081b";
export var imgWrapper = "newsBlock-new-module--img-wrapper--a0ce4";
export var inner = "newsBlock-new-module--inner--3f2b9";
export var innerPadding = "newsBlock-new-module--innerPadding--046ec";
export var newsItem = "newsBlock-new-module--news-item--b41f3";
export var newsWrapper = "newsBlock-new-module--news-wrapper--c794f";
export var textContainer = "newsBlock-new-module--text-container--ce2ca";
export var title = "newsBlock-new-module--title--472f9";