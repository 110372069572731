import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import cx from "classnames"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Link } from "gatsby-plugin-react-i18next"

import Heading from "../Elements/Heading"
import Breadcrumbs from "../Breadcrumbs"
import { getFeaturedImage } from "../../helpers/images"

import * as styles from "./newsBlock.new.module.css"

const NewsBlock = ({ limitItems = 0, isBlock, pageContext }) => {
  const { t, i18n } = useTranslation()
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(
          filter: { status: { eq: "publish" } }
          sort: { order: DESC, fields: date }
        ) {
          nodes {
            title
            link
            settings {
              language
            }
            date(formatString: "DD.MM.YYYY")
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  let news = allWpPost?.nodes?.filter(
    news => news?.settings?.language === i18n.language
  )
  if (news && !Array.isArray(news)) {
    news = [news]
  }

  if (limitItems) {
    news = allWpPost?.nodes?.slice(0, limitItems)
  }

  const parseDate = (dateString) => {
    const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
    const [day, month, year] = dateString.split('.');
    const yearNow = new Date().getFullYear();

    return `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]} ${yearNow.toString() !== year ? year : ''}`;
  }

  return (
    <div className={styles.container}>
      {!isBlock ?
      <div className={cx(styles.inner, "page_titles")}>
        <Breadcrumbs pageContext={pageContext} />
        <Heading pageTitle>{t("Новости")}</Heading>
      </div>
      : undefined}
      <div className={styles.catalog}>
        {news?.map((item, index) => (
          <Link className={styles.newsItem} key={index} to={`/news${item.link}`}>
            <div className={styles.newsWrapper}>
              <div className={styles.imgWrapper}>
                {getFeaturedImage(item)}
              </div>
              <div className={styles.textContainer}>
                <p className={cx(styles.date, 'text-minimal')}>{parseDate(item.date)}</p>
                <p className={styles.title}>{item.title}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default NewsBlock
