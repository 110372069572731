import React from 'react'
import * as styles from './News.module.css';
import NewsBlock from "../../components/NewsBlock";
import cx from 'classnames'
import {Link} from "gatsby-plugin-react-i18next";

const News = ({ pageContext }) => {
  return (
    <section className={cx(styles.container, "container")}>
      <h2 className={styles.sectionTitle}>Новости</h2>

      <NewsBlock isBlock={true} newRedisign={true} pageContext={pageContext} limitItems={3}/>

      <div className={styles.moreWrapper}>
        <Link to="/news/" className="theme-button-text">
          Далее
        </Link>
      </div>
    </section>
  )
}
export default News
