import FrisoImg from '../../assets/images/expertise/friso.svg';
import JacobsImg from '../../assets/images/expertise/jacobs.png';
import ProstokvashaImg from '../../assets/images/expertise/prostokvasha.png';
import ExpelImg from '../../assets/images/expertise/expel.png';
import BlackDeckerImg from '../../assets/images/expertise/black_decker.png';
import VitekImg from '../../assets/images/expertise/vitek.svg';


const EXPERTISE_MOCK_DATA = [
  {
    title: 'Vitek',
    description: 'Запустили Performance-кампанию для ведущего бренда бытовой техники в России',
    result: {
      title: '+77%',
      description: 'улучшили плановый показатель на Яндекс Маркете'
    },
    icon: VitekImg,
    link: '/cases/vitek'
  },
  {
    title: 'Jacobs',
    description: 'Повысили продажи новой продуктовой линейки кофе на маркетплейсах Ozon и Wildberries',
    result: {
      title: '+25%',
      description: 'к росту продаж бренда от органической линии тренда'
    },
    icon: JacobsImg,
    link: '/cases/jacobs'
  },
  {
    title: 'Простоквашино',
    description: 'Подняли продажи молочных продуктов и вывели товары бренда на первые строчки маркетплейсов',
    result: {
      title: `7,1 <span class="text-subheader">ROMI</span>`,
      description: 'на товары с средним чеком 50-90₽'
    },
    icon: ProstokvashaImg,
    link: '/cases/prostokvashino'
  },
  {
    title: 'Expel',
    description: 'Запустили performance-кампанию для маркетплейсов Ozon и Wildberries',
    result: {
      title: '+30%',
      description: 'увеличение количества поисковых запросов'
    },
    icon: ExpelImg,
    link: '/cases/expel'
  },
  {
    title: 'Black +\nDecker',
    description: 'Увеличили продажи электроинструментов BLACK+DECKER в коллаборации с фильмом «Черная Вдова»',
    result: {
      title: '+114%',
      description: 'к общему перевыполнению плана'
    },
    icon: BlackDeckerImg,
    link: '/cases/black-decker'
  },
  {
    title: 'Friso',
    description: 'Запустили Performance-кампанию для детского питания в TikTok',
    result: {
      title: '>200%',
      description: 'выполнение плана на показы и клики'
    },
    icon: FrisoImg,
    link: '/cases/friso'
  },
];

export default EXPERTISE_MOCK_DATA