import React, {useEffect, useRef, useState} from 'react'
import * as styles from './OnlineBills.module.css'
import MOCK_DATA from './mock';
import cx from 'classnames'
import {Swiper, SwiperSlide} from "swiper/react";

const OnlineBills = () => {
  const [images, setImages] = useState([]);
  const [activeId, setActiveId] = useState(0)
  const swiperRef = useRef(null);

  const handleSetImages = (images, id) => () => {
    if(Array.isArray(images)) {
      setImages(images);
      setActiveId(id);
      // Hack to update swiper
      setTimeout(() => {
        swiperRef.current.updateSlides()
      }, 100)
    }
  }

  useEffect(() => {
    setImages(MOCK_DATA[0].logos);
  }, []);

  const handleSwiper = (swiper) => {
    swiperRef.current = swiper
  }

  return (
    <div className={styles.contentContainer}>
      <h3 className={styles.sectionTitle}>Online чеки</h3>
      <div className={styles.contentInfo}>
        <div className={styles.categories}>
            {MOCK_DATA.map((item, index) => (
              <button key={index} onClick={handleSetImages(item.logos, index)} className={cx(styles.categoryChip, activeId === index ? [styles.chipActive, 'active'] : '', 'theme-button')}>
                {item.title}
              </button>
            ))}
        </div>
        <div className={styles.logos}>
          <Swiper onSwiper={handleSwiper} autoHeight={true} spaceBetween={40} slidesPerView="5" direction="vertical" breakpoints={{ 768: { direction: 'horizontal', spaceBetween: 30, autoHeight: false, slidesPerView: 'auto' } }}>
              {images.map((src, index) => (
                <SwiperSlide key={index}>
                  <div className={styles.logoWrapper}>
                    <img src={src} alt="" />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}


export default OnlineBills
