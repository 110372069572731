import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "../components/CasesBlock/casesBlock.module.css"

const getImgSrc = item => {
  return {
    src: item?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
      ?.images?.fallback?.src,
    alt: item?.featuredImage?.node?.alt || ``,
  }
}

const getFeaturedImage = item => {
  const featuredImage = {
    data: item?.featuredImage?.node?.localFile?.childImageSharp
      ?.gatsbyImageData,
    alt: item?.featuredImage?.node?.alt || ``,
  }

  return (
    featuredImage?.data && (
      <GatsbyImage
        className={styles.caseImg}
        image={featuredImage.data}
        alt={featuredImage.alt}
      />
    )
  )
}

export { getFeaturedImage, getImgSrc }
