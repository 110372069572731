// extracted by mini-css-extract-plugin
export var arrow = "newsBlock-module--arrow--ad153";
export var arrowImg = "newsBlock-module--arrowImg--2d8fd";
export var caseImg = "newsBlock-module--caseImg--69c9c";
export var catalog = "newsBlock-module--catalog--0ba97";
export var container = "newsBlock-module--container--b08d3";
export var date = "newsBlock-module--date--680c3";
export var description = "newsBlock-module--description--c7006";
export var iconHover = "newsBlock-module--iconHover--4ee08";
export var inner = "newsBlock-module--inner--254af";
export var innerPadding = "newsBlock-module--innerPadding--13b9a";
export var newsBackdrop = "newsBlock-module--newsBackdrop--1f558";
export var newsIcon = "newsBlock-module--newsIcon--b3456";
export var newsImg = "newsBlock-module--newsImg--9a8e0";
export var newsItem = "newsBlock-module--newsItem--f6486";
export var newsLink = "newsBlock-module--newsLink--9f5db";
export var newsWrapper = "newsBlock-module--newsWrapper--f3db1";
export var textContainer = "newsBlock-module--textContainer--abc70";
export var title = "newsBlock-module--title--ed9cb";