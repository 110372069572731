import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import cx from "classnames"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Link } from "gatsby-plugin-react-i18next"

import Heading from "../Elements/Heading"
import Breadcrumbs from "../Breadcrumbs"
import { getFeaturedImage } from "../../helpers/images"

import * as styles from "./newsBlock.module.css"
import { NewsIcon, NewsIconHover } from "../../assets/images"

const NewsBlock = ({ limitItems = 0, pageContext }) => {
  const { t, i18n } = useTranslation()
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(
          filter: { status: { eq: "publish" } }
          sort: { order: DESC, fields: date }
        ) {
          nodes {
            title
            link
            settings {
              language
            }
            date(formatString: "DD.MM.YYYY")
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  let news = allWpPost?.nodes?.filter(
    news => news?.settings?.language === i18n.language
  )
  if (news && !Array.isArray(news)) {
    news = [news]
  }

  if (limitItems) {
    news = allWpPost?.nodes?.slice(0, limitItems)
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.inner, "page_titles")}>
        <Breadcrumbs pageContext={pageContext} />
        <Heading pageTitle>{t("Новости")}</Heading>
      </div>
      <div className={styles.catalog}>
        {news?.map((item, index) => (
          <div className={styles.newsItem} key={index}>
            <div className={styles.newsBackdrop}>{getFeaturedImage(item)}</div>
            <Link className={styles.newsLink} to={`/news${item.link}`}>
              <span className={styles.newsWrapper}>
                <span className={styles.newsIcon}>
                  <img src={NewsIcon} alt="" />
                  <img
                    className={styles.iconHover}
                    src={NewsIconHover}
                    alt=""
                  />
                </span>
                <span className={styles.textContainer}>
                  <span className={styles.date}>{item.date}</span>
                  <span className={styles.title}>{item.title}</span>
                </span>
              </span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default NewsBlock
