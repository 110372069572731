import React from "react"
import NewsBlockNew from "./newsBlock.new";
import NewsBlockOld from "./newsBlock.old";

const NewsBlock = ({ newRedisign, isBlock, ...props }) => {

  return (
    newRedisign ?
      <NewsBlockNew isBlock={isBlock} {...props} />
      :
      <NewsBlockOld {...props} />
  )
}

export default NewsBlock
