import React from 'react'
import styled from 'styled-components'

const SeparationEl = styled.div`
    ${(props) => (
      `
        width: 100%;
        margin: ${props.disableMarginTop ? '0' : '64px'} 0 ${props.disableMarginBottom ? '0' : '64px'};
        border: 1px solid var(--color-primary-black);
        border-radius: 8px;
    
        @media (min-width: 1024px) {
            margin-top: ${props.disableMarginTop ? '0' : '96px'};
            margin-bottom: ${props.disableMarginBottom ? '0' : '96px'};
        }
      `
    )}}
`

const Separation = ({ disableMarginTop, disableMarginBottom }) => {
  return (
    <div className="container">
      <SeparationEl
        disableMarginTop={disableMarginTop}
        disableMarginBottom={disableMarginBottom}
      />
    </div>
  )
}

export default Separation
