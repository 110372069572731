import BillSvg from '../../assets/images/services_icons/bill.svg';
import PromgrammaticSvg from '../../assets/images/services_icons/programmatic.svg';
import DashboardSvg from '../../assets/images/services_icons/dashboard.svg';
import RetailAnalyticsSvg from '../../assets/images/services_icons/retail_analytics.svg';
import BagSvg from '../../assets/images/services_icons/bag.svg';
import ChartSvg from '../../assets/images/services_icons/chart.svg';

const SERVICES_MOCK_DATA = [
  {
    title: 'Perfomance',
    description: 'Реализуем высококонверсионные рекламные кампании для брендов на маркетплейсы и интернет-магазины на основе данных онлайн-транзакций.',
    icon: BillSvg
  },
  {
    title: 'Brandformance',
    description: 'Запускаем охватные рекламные кампании на сегменты онлайн-покупателей',
    icon: BagSvg
  },
  {
    title: 'Retail аналитика',
    description: 'Конкурентный анализ продаж на маркетплейсах, динамика рынка и категорий.',
    icon: RetailAnalyticsSvg
  },
  {
    title: 'Sales lift',
    description: 'Детализированный дашборд с данными продаж по проведённой рекламной кампании',
    icon: DashboardSvg
  },
  {
    title: 'Programmatic',
    description: 'Рекламные технологии на базе данных телекома и машинного обучения',
    icon: PromgrammaticSvg
  }
]

export const SERVICES_PAGE_MOCK_DATA = [
  {
    icon: BillSvg,
    title: 'DIGITAL 360 В E-COM',
    descriptionTitle: 'ЗАПУСКАЕМ РЕКЛАМНЫЕ КАМПАНИИ',
    description: 'Для брендов на маркетплейсы и интернет-магазины на основе данных транзакций и посещения страниц мобильных приложений этих магазинов.',
    additionalDescription: 'Обеспечиваем высокий ROMI за счёт использования собственных скриптов, выявляя пользователей с наибольшей вероятностью покупки в течение 21 дня.\n' +
      'Делаем полноценный sales lift по проведённой РК, устанавливая пиксель на клик и отслеживая дальнейшие действия по Stable ID пользователя.'
  },
  {
    icon: DashboardSvg,
    title: 'Sales lift',
    descriptionTitle: 'ПО АУДИТОРИИ НАШИХ СЕГМЕНТОВ',
    description: 'Происходит с помощью размещения пикселя на клик в рекламных кабинетах. При клике информация о взаимодействии с рекламой передаётся в нашу DVPlatform, где мы производим «склейку» по Stable ID и отдаём полную информацию — где, когда, за какую сумму и что купил пользователь (магазин, дата, сумма транзакции, название SKU). Отчет доступен пользователю в дашборде в DataLens.',
  },
  {
    icon: ChartSvg,
    title: 'Аналитика',
    descriptionTitle: '«РАСКРАС» АУДИТОРИИ КЛИЕНТА',
    description: '(выгрузка из CRM e — mail и тел.), конкурентный анализ по OZON и WB.',
  },
  {
    icon: BagSvg,
    title: 'ПРОДАЖА СЕГМЕНТОВ',
    descriptionTitle: 'ДАННЫЕ ПОКУПАТЕЛЕЙ ДЛЯ БРЕНДФОРМАНС И МЕДИЙНЫХ РК',
    description: 'Отгружаем в рекламные кабинеты, обновляем раз в неделю.',
  }
]

export default SERVICES_MOCK_DATA