import React from 'react'
import * as styles from './ContactWithUs.module.css'
import cx from 'classnames'
import {graphql, useStaticQuery} from "gatsby";

const ContactWithUs = ({ title }) => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          allSettings {
            themeSettingsEmailSales
          }
        }
      }
    `
  )

  const { themeSettingsEmailSales: email } = wp.allSettings

  return (
    <section className={cx(styles.container, 'container')}>
      <h2 className={styles.sectionTitle}>
        Интересно с нами поработать?
      </h2>
      <h3 className={styles.subTitle}>
        {title ? title : 'получить стоимость услуг'}
      </h3>
      <a className={cx("theme-button", styles.button)} href={"mailto:" + email}>{email}</a>
    </section>
  )
}
export default ContactWithUs
