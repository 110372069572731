import React from 'react'
import cx from "classnames";
import * as styles from './Connections.module.css'
import OnlineBills from "../OnlineBills";
import HowUseOnlineBills from "./HowUseOnlineBills";

const Connections = () => {
  return (
    <section className={cx(styles.container, 'container')}>
      <h2 className={styles.sectionTitle}>Подключения</h2>
      <div className={styles.contentWrapper}>
        <HowUseOnlineBills />
        <OnlineBills />
      </div>
    </section>
  )
}

export default Connections
