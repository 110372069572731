import React from 'react'
import * as styles from './Services.module.css'
import cx from 'classnames';
import MOCK_DATA from './SERVICES_MOCK_DATA'
import {Link} from "gatsby-plugin-react-i18next";

const Services = () => {
  return (
    <section className={cx(styles.wrapper, 'container')}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>Услуги</h2>
        <div className={styles.items}>
          {MOCK_DATA.map((item, index) => (
            <div key={index} className={styles.item}>
              <div className={styles.itemImage}>
                <img src={item.icon} alt=""/>
              </div>
              <div className={styles.itemContent}>
                <span className={styles.itemTitle}>{item.title}</span>
                <p className={styles.itemDescription}>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.moreWrapper}>
        <Link to="/services/" className="theme-button-text">
          Подробнее
        </Link>
      </div>
    </section>
  )
}
export default Services
