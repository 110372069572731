import React, {useRef, useEffect} from 'react'
import cx from 'classnames'
import * as styles from './Expertise.module.css'
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/free-mode';
import MOCK_DATA from './EXPERTISE_MOCK_DATA'
import useMediaQuery from "../../hooks/useMediaQuery";
import {Link} from "gatsby-plugin-react-i18next";

const Expertise = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const swiperRef = useRef(null);

  useEffect(() => {
    if(!swiperRef.current) return;

    if(!isMobile) {
      swiperRef.current?.disable();
    } else {
      swiperRef.current?.enable();
    }
  }, [isMobile, swiperRef]);

  return (
    <section className={cx(styles.wrapper, 'container')}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>Наша экспертиза</h2>
        <div className={styles.casesSlider}>
          <Swiper
            onSwiper={(swiper) => swiperRef.current = swiper }
            slidesPerView="auto"
            spaceBetween={24}
            breakpoints={{
              1023: {
                spaceBetween: 0
              }
            }}
          >
            {MOCK_DATA.map((item, index) => (
              <SwiperSlide key={index} className={styles.slide}>
                <Link to={item.link} className={styles.caseLink}>
                  <div className={styles.caseHead}>
                    <h5>{item.title}</h5>
                    <div className={styles.caseIconWrapper}>
                      <img src={item.icon} alt=""/>
                    </div>
                  </div>
                  <div className={styles.caseBody}>
                    <div className={styles.caseResult}>
                      <span className={styles.caseResultTitle} dangerouslySetInnerHTML={{ __html: item.result.title }} />
                      <span className={cx(styles.caseResultDescription, 'text-minimal')} dangerouslySetInnerHTML={{ __html: item.result.description }} />
                    </div>
                    <p className={styles.caseDescription}>{item.description}</p>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={styles.moreWrapper}>
        <Link to="/cases/" className="theme-button-text">
          Далее
        </Link>
      </div>
    </section>
  )
}

export default Expertise
