 // маркетплейсы
 import MegamarketSvg from '../../assets/images/online_bills/marketplaces/megamarket.svg'
 import OzonSvg from '../../assets/images/online_bills/marketplaces/ozon.svg'

 // товары для животных
 import BeathovenSvg from '../../assets/images/online_bills/animals/beathoven.svg'
 import FourLegsSvg from '../../assets/images/online_bills/animals/four_legs.svg'

 // продукты питания
import GlobusSvg from '../../assets/images/online_bills/food/globus.svg'
import SamokatSvg from '../../assets/images/online_bills/food/samokat.svg'
import SberMarketSvg from '../../assets/images/online_bills/food/sber_market.svg'

 // здоровье
 import Health1Svg from '../../assets/images/online_bills/health/health_1.svg'
 import Health2Svg from '../../assets/images/online_bills/health/health_2.svg'
 import Health3Svg from '../../assets/images/online_bills/health/health_3.svg'

 // дети
import AkushSvg from '../../assets/images/online_bills/kids/akush.svg'
import DetskiySvg from '../../assets/images/online_bills/kids/detskiy.svg'

 // техника и электроника
 import CitylinkSvg from '../../assets/images/online_bills/electronics/citylink.svg'
 import EldoradoSvg from '../../assets/images/online_bills/electronics/eldorado.svg'
 import HolodilnikSvg from '../../assets/images/online_bills/electronics/holodilnik.svg'

 // дом и ремонт
 import AllInstrumentsSvg from '../../assets/images/online_bills/repair/all_instruments.svg'
 import LeroyMerlinSvg from '../../assets/images/online_bills/repair/leroy_merlin.svg'


 const onlineBillsMock = [
  {
    title: 'маркетплейсы',
    logos: [
      OzonSvg,
      MegamarketSvg
    ]
  },
  {
    title: 'продукты питания',
    logos: [
      GlobusSvg,
      SamokatSvg,
      SberMarketSvg
    ]
  },
  {
    title: 'здоровье',
    logos: [
      Health1Svg,
      Health2Svg,
      Health3Svg
    ]
  },
  {
    title: 'дети',
    logos: [
      AkushSvg,
      DetskiySvg
    ]
  },
  {
    title: 'техника и электроника',
    logos: [
      EldoradoSvg,
      HolodilnikSvg,
      CitylinkSvg
    ]
  },
  {
    title: 'дом и ремонт',
    logos: [
      AllInstrumentsSvg,
      LeroyMerlinSvg
    ]
  },
]

export default onlineBillsMock;
